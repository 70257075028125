import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import imagem02 from '../../../assets/imagem02.jpg';
import imagem003 from '../../../assets/imagem003.jpg';
import imagem0004 from '../../../assets/imagem0004.jpg';
import '../Carrosel/style.css';

const Carrossel = () => {
  return (
    <div className="carrossel-container">
      <Carousel id='carrosel' fade interval={1887}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={imagem02}
            alt="Gestão para o seu negócio"
          />
          <Carousel.Caption className="text-center">
            <h3 className="carrossel-title">Gestão para o seu negócio</h3>
            <p className="carrossel-text">Controle é a palavra chave quando o assunto é gestão empresarial. Acompanhar tudo o que é realizado e obter levantamentos referentes a períodos específicos permite aos empresários entender corretamente o seu nicho de mercado.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={imagem003}
            alt="Controle as suas vendas com eficiência"
          />
          <Carousel.Caption className="text-center">
            <h3 className="carrossel-title">Controle as suas vendas com eficiência</h3>
            <p className="carrossel-text">Não deixe o caos atrapalhar o sucesso do seu negócio. Invista em recursos que ajudarão a organizar sua empresa e transforme a confusão em produtividade e eficiência.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={imagem0004}
            alt="Recursos que te ajudarão a organizar sua empresa"
          />
          <Carousel.Caption className="text-center">
            <h3 className="carrossel-title">Recursos que te ajudarão a organizar sua empresa</h3>
            <p className="carrossel-text">Um sistema de gestão é a chave para o sucesso do seu negócio, ele permite uma visão completa e estratégica da empresa, tornando possível identificar oportunidades e desafios, e tomar decisões mais precisas e rápidas para alcançar seus objetivos.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Carrossel;
